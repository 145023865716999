import { take, put, call, select } from 'redux-saga/effects';
import initPendo from 'helpers/pendoHelper';
import { createUser, getTags } from 'services/api/participant';
import { DefaultTenant } from 'constants/defaultValues';
import { axiosInstance } from 'services/api/base';
import { changeUserPassword, fetchUserTenants } from 'services/api/user';
import { getOrganizations } from 'services/api/assessment';
import { getRole } from 'services/api/generic';
import { updateLastLogin } from 'services/api/provider';
import { FeatureFlagsActions } from 'store/feature-flags';
// eslint-disable-next-line import/no-cycle
import { OutcomesFeatureFlagsActions } from 'store/outcomes-feature-flags';
import Swal from 'sweetalert2';
import { setSessionData } from 'helpers/sessionStorage';
import {
  UserActions as Actions,
  UserTypes as Types,
  UserSelectors as Selectors,
} from './userReducer';

function* setChangePassword({ participantId, password }) {
  yield put(Actions.setChangePasswordLoading(true));

  try {
    yield call(changeUserPassword, participantId, password);

    Swal.fire({
      title: 'Success',
      text: 'Password change correctly',
      icon: 'success',
    });
    yield put(Actions.setChangePasswordOk(true));
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'Failed to change password, try again later or contact an administrator',
      icon: 'error',
    });
    yield put(
      Actions.setUserErrors({
        error,
        message: '[changeUserPassword]: error setting the password',
      })
    );
  } finally {
    yield put(Actions.setChangePasswordLoading(false));
  }
}

function* setCreateUser({ values }) {
  yield put(Actions.setCreateUserLoading(true));

  try {
    yield call(createUser, values);
    yield put(Actions.setCreateUserOk(true));

    Swal.fire({
      title: 'Saved',
      icon: 'warning',
    });
  } catch (err) {
    const code =
      err.response.data?.message || err.response.data?.error?.message || '';
    let text;

    if (code === 'Error: User aleady exist in dictionary') {
      text =
        'Error External Id for this user already exists in this organization';
    } else if (code === 'User Email already in use') {
      text = 'This email is already in use';
    } else {
      text = "Error couldn't create user, try again later";
    }

    Swal.fire({
      title: 'Error',
      text,
      icon: 'info',
    });

    yield put(
      Actions.setUserErrors({
        error: err,
        message: '[createUser]: Failed to save new user',
      })
    );
  } finally {
    yield put(Actions.setCreateUserLoading(false));
  }
}

function* fetchOrganizations() {
  try {
    yield put(Actions.setOrganizationsLoading(true));
    const response = yield call(getOrganizations);

    yield put(Actions.fetchOrganizationsSuccess(response));
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[createUser]: Failed to save new user',
      })
    );
    yield put(Actions.fetchOrganizationsSuccess(null));
  } finally {
    yield put(Actions.setOrganizationsLoading(false));
  }
}

export function* setUpdateLastLogin(userId) {
  try {
    yield call(updateLastLogin, userId);
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[updateLastLogin]: problem updating last login',
      })
    );
  }
}

function* setLastLogin(action) {
  const { userId } = action;

  try {
    yield call(setUpdateLastLogin, userId);
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[setUpdateLastLogin]: problem getting data',
      })
    );
  }
}

let userRoleController;
function* fetchUserRole(action) {
  if (userRoleController) {
    userRoleController.abort();
  }

  const { authUser, tenant } = action;

  yield put(Actions.setUserRoleIsLoading(true));

  try {
    userRoleController = new AbortController();
    const params = {
      token: authUser.token,
      signal: userRoleController.signal,
    };
    if (tenant) {
      params.tenant = tenant;
    }
    const response = yield call(getRole, params);
    const {
      email,
      email_verified: emailVerified,
      sub,
      token,
      ...rest
    } = authUser;

    yield put(
      Actions.fetchUserRoleSuccess({
        ...response[0],
        auth0: { email, email_verified: emailVerified, sub, ...rest },
      })
    );
    yield put(Actions.setLastLogin(response[0].user_id));
    yield put(FeatureFlagsActions.fetchFeatureFlags());

    // NOTE: this only works because we're doing everything client side.
    // eventually should be replaced with an env var for domain
    const currentHost = window.location.hostname;

    const data = {
      email,
      emailVerified,
      fullName: response[0]?.full_name,
      authId: sub,
      id: `${response[0].user_id}-${currentHost}`,
      role: response[0].name,
      roleId: response[0].role_id,
      tenant: tenant || response[0].tenant_id,
    };

    const account = {
      id: response[0]?.tenant?.name,
    };

    initPendo(data, account);

    const tenants = yield select(Selectors.getTenantList);

    if (tenants.length === 0) {
      yield put(Actions.fetchTenantList());
    }
  } catch (error) {
    yield put(
      Actions.setUserErrors({
        error,
        message: '[getUserRole]: problem getting data',
        unauthorized: true,
      })
    );
  } finally {
    yield put(Actions.setUserRoleIsLoading(false));
  }
}

function* fetchTags(action) {
  const tags = yield select(Selectors.getTags);

  const testStringCategories = action.tags;
  const regexp = /\d+/;
  const categoryArr = testStringCategories.match(regexp);

  const hasAllKeys = categoryArr.every((cat) =>
    tags.hasOwnProperty(Number(cat))
  );

  if (hasAllKeys === false) {
    const response = yield call(getTags, action.tags);
    const tempObj = {};

    response.forEach((el) => {
      if (tempObj.hasOwnProperty(el.category_id) === false) {
        tempObj[el.category_id] = new Array(el);
      } else {
        tempObj[el.category_id].push(el);
      }
    });

    yield put(Actions.fetchTagsSuccess({ ...tags, ...tempObj }));
  }
}

function* getTenantId(replace, tenantId) {
  // If the user is explicitly changing the tenant manually, return their choice
  if (replace) {
    return tenantId;
  }
  // Otherwise, the correct tenant id is the one returned from Get User Role
  yield take(Types.FETCH_USER_ROLE_SUCCESS);
  const userRole = yield select(Selectors.getUserRole);
  return userRole.tenant.id;
}

function* setTenant({ tenantId, authUser, replace }) {
  try {
    yield put(Actions.setTenantLoading(true));

    yield put({ type: 'RESET' });

    yield put(
      Actions.fetchUserRole(
        {
          ...authUser,
          token: axiosInstance.defaults.headers.common.Authorization,
        },
        tenantId
      )
    );

    const updatedTenantId = yield getTenantId(replace, tenantId);
    axiosInstance.defaults.headers.common['x-tenant'] = updatedTenantId;
    yield put(Actions.setTenantSuccess(updatedTenantId));
    yield put(OutcomesFeatureFlagsActions.fetchOutcomesFeatureFlags());
    setSessionData({ tenantId: updatedTenantId });
    if (replace) {
      window.location.replace('/app');
    }
    if (updatedTenantId !== tenantId) {
      window.location.reload();
    }
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: error,
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      confirmButtonText: 'Continue',
    });
  } finally {
    yield put(Actions.setTenantLoading(false));
  }
}

function* fetchTenantList() {
  try {
    yield put(Actions.setTenantListLoading(true));
    const response = yield call(fetchUserTenants);

    yield put(Actions.fetchTenantListSuccess(response));
    yield put(OutcomesFeatureFlagsActions.fetchOutcomesFeatureFlags());
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'Error getting tenants',
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      confirmButtonText: 'Continue',
    });
  } finally {
    yield put(Actions.setTenantListLoading(false));
  }
}

function* setTenantScope({ scope, tenantList }) {
  try {
    yield put(Actions.setTenantLoading(true));
    const userRole = yield select(Selectors.getUserRole);
    let selectedScope = yield select(Selectors.getCurrentTenant);

    if (selectedScope === DefaultTenant) {
      selectedScope = tenantList[0].id;
    }

    if (scope !== selectedScope) {
      yield put(Actions.setTenant(scope, userRole.auth0));
    }
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'Error setting tenant',
      icon: 'error',
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      confirmButtonText: 'Continue',
    });
  } finally {
    yield put(Actions.setTenantLoading(false));
  }
}

export default () => ({
  [Types.FETCH_ORGANIZATIONS]: fetchOrganizations,
  [Types.SET_CHANGE_PASSWORD]: setChangePassword,
  [Types.SET_CREATE_USER]: setCreateUser,
  [Types.FETCH_USER_ROLE]: fetchUserRole,
  [Types.FETCH_TAGS]: fetchTags,
  [Types.SET_LAST_LOGIN]: setLastLogin,
  [Types.SET_TENANT]: setTenant,
  [Types.FETCH_TENANT_LIST]: fetchTenantList,
  [Types.SET_TENANT_SCOPE]: setTenantScope,
});
